<template>
  <!-- 课程详情页 讨论部分 讨论区主体列表 -->
  <div class="course-discuss-area-wrapper" v-if="comment.level !== 1">
    <div class="comments">
      <!-- 一级留言 -->
      <div class="summary flex-align-between">
        <!-- 个人信息 -->
        <div class="personal-info flex-align f20">
          <img class="avatar" :src="downloadURL+comment.userAvatar" alt="" :onerror="$store.state.user.defaultAvatar">
          <div>
            <p class="name">{{comment.userName}}
            </p>
            <p style="font-size:12px">{{comment.createTime}}</p>
          </div>
        </div>
        <!-- 右侧功能条 -->
        <div class="toolbar flex-layout">
          <button class="btn" @click="reply(comment)">回复</button>
          <button class="btn" v-if="(comment.userName === userInfo.name)|| $store.state.user.isAdmin"
            @click="deleteReply(comment)">删除</button>
        </div>
      </div>
      <!-- 留言内容 -->
      <div class="reply-content" style="white-space: pre-line;">{{comment.content}}</div>
      <!-- 留言回复框 -->
      <div class="textarea-container parent-textarea" v-if="$store.state.discuss.replyArea === comment.id">
        <el-input type="textarea" :rows="2" placeholder="回复：" resize="none" v-model="textarea" class="textarea"
          :maxlength="255" show-word-limit>
        </el-input>
        <div class="control-textarea">
          <el-button @click="$store.state.discuss.replyArea = -1">取 消</el-button>
          <el-button type="primary" :plain="true" @click="submitReply(comment)">提 交</el-button>
        </div>
      </div>
      <!-- 二级子留言 -->
      <!-- <div class="child-comment" v-for="(item,index) in comment.children" :key="index"> -->
      <div class="child-comment" v-for="(item,index) in commenChildList[pages.currentPage-1]" :key="index">
        <div class="child-item flex-between">
          <!-- 个人头像、回复信息 -->
          <div class="person-info flex-start">
            <img :src="downloadURL+item.userAvatar" alt="" class="avatar" :onerror="$store.state.user.defaultAvatar">
            <p class="f16 responder">{{item.userName}}<span class="replay-text"
                style="color:#2373EC">回复</span>{{item.parentUserName}}：</p>
            <p class="child-content">{{item.content}}</p>
          </div>
          <!-- 右侧工具栏 -->
          <div class="toolbar">
            <span class="time">{{item.createTime}}</span>
            <button class="btn" @click="reply(item)">回复</button>
            <button class="btn" v-if="(item.userName === userInfo.name)|| $store.state.user.isAdmin"
              @click="deleteReply(item)">删除</button>
          </div>
        </div>
        <!-- 子级留言回复框 -->
        <div class="textarea-container" v-if="$store.state.discuss.replyArea === item.id">
          <el-input type="textarea" :rows="2" placeholder="回复：" resize="none" v-model="textarea" class="textarea"
            :maxlength="255" show-word-limit>
          </el-input>
          <div class="control-textarea">
            <el-button @click="$store.state.discuss.replyArea = -1">取 消</el-button>
            <el-button type="primary" :plain="true" @click="submitReply(item)">提 交</el-button>
          </div>
        </div>
        <!--  @pagination="handlePageChange" -->
      </div>
      <!-- <pagination v-show="pages.total > 5" :total="pages.total" :page.sync="pages.currentPage"
        :limit.sync="pages.pageSize">
      </pagination> -->
      <el-pagination v-show="pages.total > 5" :current-page.sync="pages.currentPage" :page-size.sync="pages.pageSize"
        :total="pages.total" layout="prev, pager, next, total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import {
    chunk
  } from 'lodash/array'
  export default {
    inject: ['reload'],
    props: {
      comment: {
        type: Object,
        default: {}
      }
    },
    data() {
      return {
        textarea: '', //回复内容
        userInfo: {}, //个人信息
        pages: { //分页
          currentPage: 1,
          pageSize: 5,
          total: 0,
        },
        commenChildList: [],
      }
    },
    watch: {
      'comment': {
        handler(newVal, oldVal) {
          // console.log(newVal);
          if (JSON.stringify(newVal.children) !== '[]' && newVal.children) {
            this.pages.total = newVal.children.length;
            this.commenChildList = chunk(newVal.children, 5);
          }
        },
        immediate: true,
      }
    },

    methods: {
      // 回复
      reply(item) {
        this.$store.state.discuss.replyArea = item.id
      },
      // 提交回复
      async submitReply(item) {
        if (!this.textarea.trim()) {
          this.$alert('填写内容不可为空', '提示', {
            confirmButtonText: '确定',
          });
          return
        }
        let params = {
          courseId: Number(this.$route.query.listId),
          parentId: item.id,
          content: this.textarea,
        }
        // console.log(params);
        let resData = await this.$Api.Course.createDisscus(params);
        if (resData.code === 200 && resData.msg === "请求成功") {
          this.$message({
            message: '回复成功',
            type: 'success'
          });
          this.currentReply = 0;
          setTimeout(() => {
            this.$store.state.discuss.replyArea = -1
            this.$emit("replay", true);
            this.textarea = "";
            // this.reload();
          }, 100)
        } else {
          this.$message.error(resData.msg);
        }
      },
      // 删除留言
      deleteReply(item) {
        this.$confirm('删除该内容会导致所有回复内容都被删除，是否删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // let resData = this.$Api.Course.commentDel(item.id)
          this.$Api.Course.commentDel(item.id).then((resData) => {
            //  console.log(resData)
            if (resData.code === 200 && resData.msg === "请求成功") {
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              setTimeout(() => {
                this.$emit("replay", true);
                // this.reload();
              }, 100)
            } else {
              this.$message.error(resData.msg);
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
    },
    mounted() {
      this.userInfo = this.$store.state.user.userInfo;
    },
  };
</script>

<style lang="less" scoped>
  .course-discuss-area-wrapper {
    margin: 0 auto 29px;

    .comments {
      padding: 0px 15px 10px 10px;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 11px;
      }

      .name {
        font-size: 12px;
        font-weight: 500;
      }

      .toolbar {
        flex-shrink: 0;
        padding-top: 8px;
        font-size: 12px;

        .btn {
          margin-left: 35px;
          font-size: 12px;
        }
      }

      .reply-content {
        margin: 15px auto;
        margin-left: 50px;
        border-radius: 4px;
        padding: 16px;
        // max-width: 1000px;
        min-height: 44px;
        word-break: break-all;

        color: #666;
        font-size: 12px;

        background-color: #f9f9f9;
      }

      .child-comment {
        margin-bottom: 15px;
        margin-left: 50px;

        .avatar {
          flex-shrink: 0;
          width: 33px;
          height: 33px;
        }

        .child-content {
          // word-wrap: break-word;
          word-break: break-all;
        }
      }

      .responder {
        flex-shrink: 0;
        line-height: 33px;
        font-size: 12px;

        .replay-text {
          margin: 0 7px;
        }
      }

      .child-content {
        display: inline-block;
        padding-top: 6px;
        padding-right: 10px;
        // max-width: 500px;
      }

      .textarea-container {

        &.parent-textarea {
          margin: 15px auto;
        }

        .control-textarea {
          margin-top: 10px;
          text-align: right;

          /deep/ .el-button {
            font-size: 12px;
            padding: 8px 20px;
          }
        }

        .textarea {
          display: block;
          font-size: 16px;
          margin-left: 50px;
          margin-top: 10px;
          width: auto;
        }
      }

      .btn {
        cursor: pointer;
      }
    }

    /deep/ .el-pagination {
      margin: 30px 0 0px 40px;
    }
  }
</style>